import { createGlobalStyle } from 'styled-components';

import MoskExtraBoldTtf from './mosk-extra-bold-800.ttf';
import MoskExtraBoldWoff from './mosk-extra-bold-800.woff';
import MoskExtraBoldWoff2 from './mosk-extra-bold-800.woff2';
import MoskMediumTtf from './mosk-medium-500.ttf';
import MoskMediumWoff from './mosk-medium-500.woff';
import MoskMediumWoff2 from './mosk-medium-500.woff2';
import AkkuratTtf from './akkurat.ttf';
import AkkuratWoff from './akkurat.woff';
import AkkuratWoff2 from './akkurat.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: Akkurat;
    src:
      url(${AkkuratTtf}) format('truetype'),
      url(${AkkuratWoff}) format('woff'),
      url(${AkkuratWoff2}) format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: 'Mosk Bold';
    src:
      url(${MoskExtraBoldTtf}) format('truetype'),
      url(${MoskExtraBoldWoff}) format('woff'),
      url(${MoskExtraBoldWoff2}) format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: 'Mosk Medium';
    src:
      url(${MoskMediumTtf}) format('truetype'),
      url(${MoskMediumWoff}) format('woff'),
      url(${MoskMediumWoff2}) format('woff2');
    font-display: swap;
  }

`;
