/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import styled, { keyframes } from 'styled-components';
import { AnimationControls, motion, TargetAndTransition, Transition, VariantLabels, Variants } from 'framer-motion';
import { Link } from 'gatsby';

import breakpoints from '../../styles/breakpoints';

type ContainerProps = {
  variants: Variants | undefined,
  animate: boolean | VariantLabels | AnimationControls | TargetAndTransition | undefined,
  transition: Transition | undefined,
};

type LogoWrapperProps = {
  light?: boolean,
  to: string,
  onClick: ((event: React.MouseEvent<HTMLAnchorElement>) => void) | undefined,
  isHidden: boolean,
};

const Container = styled(motion.div) <ContainerProps>`
  display: grid;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
  pointer-events: none;
  z-index: 2;

  ${breakpoints.xs`
    grid-template-columns: 1fr 30px;
    height: 50px;
    justify-items: start;
    padding: 0 15px;
  `}
  ${breakpoints.lg`
    justify-items: start;
    grid-template-columns: 1fr 50px;
    height: 75px;
    padding: 15px 35px;
    background-color: unset;
  `}
`;

const Logo = styled('img')`

${breakpoints.xs`
    width: 120px;
    height: 20px;
  `}
  ${breakpoints.md`
    width: 180px;
    height: 30px;
  `}
  ${breakpoints.lg`
    width: 240px;
    height: 40px;
  `}
`;

const LogoWrapper = styled(Link) <LogoWrapperProps>`
  ${({ isHidden }) => (isHidden ? '' : 'pointer-events: auto')};
  ${breakpoints.xs`
  padding: 15px 0 0 0px;
    grid-column: 1/2;
  `}
  ${breakpoints.lg`
    grid-column: 1/2;
  `}

  &:focus {
    outline: thin dotted ${({ theme, light }) => (light ? theme.color.basic.white : theme.color.basic.black)};
  }
  opacity: inherit;
`;

const saturnRotate = keyframes`
  0% { transform: rotate(180deg); }
`;

const moonAnimation = keyframes`
  0% {
    left: -20%;
    top: 10%;
    opacity: 1;
  }

  5% {
    left: -10%;
    top: -20%;
    opacity: 1;
  }

  10% {
    left: 0%;
    top: -30%;
    opacity: 0;
  }

  25% {
    left: 10%;
    top: -35%;
    opacity: 0;
  }

  40% {
    left: 80%;
    top: -20%;
    opacity: 0;
  }

  45% {
    left: 85%;
    top: -10%;
    opacity: 1;
  }

  52% {
    left: 94%;
    top: 18%;
    opacity: 1;
  }

  60% {
    left: 80%;
    top: 50%;
    opacity: 1;
  }

  75% {
    left: 45%;
    top: 80%;
    opacity: 1;
  }

  90% {
    left: 0%;
    top: 60%;
    opacity: 1;
  }

  100% {
    left: -20%;
    top: 10%;
    opacity: 1;
  }

`;

type StyledBurgerProps = {
  open: boolean,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  'aria-label': string,
  type: 'button' | 'submit' | 'reset' | undefined,
  isHidden: boolean,
  light?: boolean,
};

const StyledBurger = styled.button<StyledBurgerProps>`
  position: relative;
  width: 35px;
  height: 35px;
  margin-top: 20px;
  margin-left: 0;
  transition: all 300ms cubic-bezier(0.61, 0.01, 0.42, 1);
  cursor: pointer;
  background: transparent;
  border: none;
  opacity: inherit;
  ${({ isHidden }) => (isHidden ? '' : 'pointer-events: auto')};
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    position: absolute;
    width: 18px;
    border-radius: 10px;
    display: block;
    transition: all 0.3s linear;
    background: ${({ theme, light }) => (light ? theme.color.basic.white : theme.color.basic.black)};
    border: 2px solid ${({ theme, light }) => (light ? theme.color.basic.white : theme.color.basic.black)};
  }

  div:nth-child(1) {
    top: 0;
    transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    margin-top: ${({ open }) => (open ? '5px' : '0px')};
    margin-left: ${({ open }) => (open ? '-2px' : '0px')};
  }

  div:nth-child(2) {
    top: 6px;
    width: ${({ open }) => (open ? '0px' : '12px')};
    margin-left: ${({ open }) => (open ? '10px' : '0px')};
    border-style: ${({ open }) => (open ? 'none' : 'solid')};
  }

  div:nth-child(3) {
    top: 12px;
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    margin-top: ${({ open }) => (open ? '-7px' : '0px')};
    margin-left: ${({ open }) => (open ? '-2px' : '0px')};
  }

  div:nth-child(3)::before {
    content: none;
    background: ${({ theme, open, light }) => (open ? theme.color.basic.black : (light ? theme.color.butterscotch.main : theme.color.hamam.alternative))};
  }

  :hover {
    transition-delay: 100ms;
    transform: scale(1.1);
    color: ${({ theme, light, open }) => (open || light ? theme.color.basic.white : theme.color.basic.black)};
    div {
      animation: ${saturnRotate} 300ms linear 1;
      top: 0;
      background: transparent;

      :nth-child(1) {
        opacity: 0;
      }

      :nth-child(2) {
        transform: rotate(90deg);
        margin-top: -15px;
        margin-left: 1px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        content: '';
        background: ${({ theme, light, open }) => (open || light ? theme.color.basic.white : theme.color.basic.black)};
        display: block;
        position: relative;
        top: 0.25px;
        border-style: ${({ open }) => open && 'solid'};
      }

      :nth-child(3) {
        transform: rotate(12deg);
        margin-top: 4px;
        margin-left: -4.5px;
        height: 9px;
        width: 25px;
        border-radius: 50%;
      }

      :nth-child(3)::before {
        content: '';
        display: block;
        width: 2px;
        height: 2px;
        position: relative;
        top: 0.25px;
        opacity: 1;
        transition: opacity 600ms cubic-bezier(0.61, 0.01, 0.42, 1);
        animation: ${moonAnimation} 2000ms linear infinite;
        border-radius: 50%;
        border: 2px solid ${({ theme, light, open }) => (open || light ? theme.color.basic.white : theme.color.basic.black)};
        background: ${({ theme, open, light }) => (open ? theme.color.basic.black : (light ? theme.color.butterscotch.main : theme.color.hamam.alternative))};
      }
    }
    /* stylelint-enable */
  }
`;

type NavProps = {
  initial: unknown,
  animate: unknown,
  transition: unknown,
};

const Navigation = styled(motion.nav) <NavProps>`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.color.basic.black};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  z-index: 2;
`;

type NavLinkProps = {
  to: string,
  activeClassName: string,
  onClick: ((event: React.MouseEvent<HTMLAnchorElement>) => void) | undefined,
};

const NavLink = styled(Link) <NavLinkProps>`
  font-size: 40px;
  ${breakpoints.xs`
    font-weight: 800;
    letter-spacing: 0.5px;
    &.active {
      border-bottom: ${({ theme }) => `5px ${theme.color.butterscotch.main} solid`};
    }
    :hover {
      border-bottom: 5px ${({ theme }) => theme.color.butterscotch.main} solid;
    }
  `}

  ${breakpoints.lg`
    font-weight: 900;
    &.active {
      border-bottom: ${({ theme }) => `10px ${theme.color.butterscotch.main} solid`};
    }
    :hover {
      border-bottom: 10px ${({ theme }) => theme.color.butterscotch.main} solid;
    }
  `}

  font-family: ${({ theme }) => theme.typography.mosk.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${({ theme }) => theme.color.basic.white};
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
`;

type NavListProps = {
  initial: unknown,
  animate: unknown,
  transition: unknown,
};

const NavList = styled(motion.ul) <NavListProps>`
  text-align: right;

  ${breakpoints.xs`
    padding-right: 25px;
  `}
  ${breakpoints.lg`
    padding-right: 50px;
  `}
`;

const NavItem = styled(motion.li)`
  margin-bottom: 25px;
`;

export {
  Container,
  LogoWrapper,
  Logo,
  StyledBurger,
  Navigation,
  NavLink,
  NavList,
  NavItem,
};
