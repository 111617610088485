import { DefaultTheme } from 'styled-components';

/*
type TypeDigitalTheme = {
  color: {
    butterscotch: {
      main: string,
      tint: string,
      shade: string,
      alternative: string,
    },
    hamam: {
      main: string,
      alternative: string,
      tint: string,
      shade: string,
    },
    basic: {
      white: string,
      black: string,
      silver: string,
    },
  },
  gradients: {
    radialNight: string,
    linearDay: string,
  },
  typography: {
    mosk: {
      medium: string,
      bold: string,
    },
    akkurat: string,
  },
};
*/

const defaultTheme: DefaultTheme = {
  color: {
    butterscotch: {
      main: '#ff5959',
      tint: '#ff8c8c',
      shade: '#bf4361',
      alternative: '#a63245',
    },
    hamam: {
      main: '#6cd9d9',
      alternative: '#e6ffff',
      tint: '#98d9d9',
      shade: '#397480',
    },
    basic: {
      white: '#ffffff',
      black: '#323232',
      silver: '#fafafa',
    },
  },
  gradients: {
    radialNight: `radial-gradient(circle at 50% 44%, #ff5959, #bf4361 131%);`,
    linearDay: `linear-gradient(254deg, #ff8c8c 100%, #ff5959);`,
  },
  typography: {
    mosk: {
      medium: 'Mosk Medium',
      bold: 'Mosk Bold',
    },
    akkurat: 'Akkurat',
  },
};

export default defaultTheme;
