import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const HeroHeadline = styled.h1`
  ${breakpoints.xs`
    width: 320px;
    height: 150px;
    font-size: 32px;
    line-height: 1.19;
    letter-spacing: 0.5px;
    text-align: center;
    margin-top: 100px;
  `}
  ${breakpoints.md`
    width: 350px;
    font-size: 45px;
    margin-top: 150px;
  `}
  ${breakpoints.lg`
    width: 660px;
    height: 448px;
    font-size: 100px;
    line-height: 1.08;
    letter-spacing: 1.5px;
    text-align: left;
    margin-top: 100px
  `}
  ${breakpoints.xl`
    width: 992px;
    height: 448px;
    font-size: 120px;
    font-stretch: normal;
    font-style: normal;
  `}
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  text-shadow: 0 3px 15px rgba(50, 50, 50, 0.1);
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme }) => theme.color.basic.white};
`;

const PitchHeadline = styled.h2`
  ${breakpoints.xs`
    width: 320px;
    height: 150px;
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 0.5px;
    text-align: center;
    margin-top: 100px;
  `}
  ${breakpoints.lg`
    width: 800px;
    height: 400px;
    font-size: 64px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1.5px;
    text-align: right;
  `}
  ${breakpoints.xl`
    width: 1300px;
    height: 400px;
    font-size: 80px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1.5px;
    text-align: right;
  `}
  text-shadow: 0 3px 15px rgba(50, 50, 50, 0.1);
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme }) => theme.color.basic.black};
`;

const SmallHeadline = styled.span<{light?: boolean}>`
  ${breakpoints.xs`
  height: 16px;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 3.5px;
  text-align: center;
  `}
  ${breakpoints.lg`
  height: 30px;
  font-size: 24px;
  line-height: 1.67;
  letter-spacing: 5px;
  `}
  ${breakpoints.xl`
  height: 40px;
  font-size: 24px;
  line-height: 1.67;
  letter-spacing: 5px;
  `}
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  opacity: 0.8;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  text-align: center;
  color: ${({ theme, light }) => (light ? theme.color.basic.white : theme.color.basic.black)};
  place-self: center;
  text-transform: uppercase;
`;

const PageHeadline = styled.h1<{light?: boolean}>`
  ${breakpoints.xs`
    font-size: 28px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 0.5px;
  `}
  ${breakpoints.lg`
    font-size: 40px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1.5px;
  `}
  ${breakpoints.xl`
    font-size: 56px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1.5px;
  `}
  margin-bottom: 30px;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme, light }) => (light ? theme.color.basic.white : theme.color.basic.black)};
`;

const SectionHeadline = styled.h2<{dark?: boolean}>`
  ${breakpoints.xs`
    font-size: 26px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: 0.3px;
  `}
  ${breakpoints.lg`
    font-size: 28px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: 0.3px;
  `}
  ${breakpoints.xl`
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
  `}
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme, dark }) => (dark ? theme.color.basic.black : theme.color.basic.white)};
  margin-bottom: 30px;
`;

const ContactHeadline = styled.h2`
  ${breakpoints.xs`
    font-size: 22px;
    line-height: 1.23;
    letter-spacing: 0.3px;
    margin-bottom: 20px;
  `}
  ${breakpoints.md`
    font-size: 26px;
  `}
  ${breakpoints.lg`
    font-size: 24px;
    text-align: left;
    margin-bottom: 0;
  `}
  ${breakpoints.xl`
    font-size: 32px;
    line-height: normal;
    letter-spacing: 0.5px;
  `}
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  height: 30%;
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme }) => (theme.color.hamam.shade)};
`;

const ContactSectionHeadline = styled.h1`
  ${breakpoints.xs`
    font-size: 32px;
    line-height: 1.19;
    letter-spacing: 0.5px;
  `}
  ${breakpoints.md`
    font-size: 42px;
  `}
  ${breakpoints.lg`
    font-size: 36px;
    letter-spacing: 1px;
    text-align: left;
  `}
  ${breakpoints.xl`
    font-size: 42px;
    line-height: 1.08;
    letter-spacing: 1.5px;
  `}
  width: 100%;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  text-shadow: 0 3px 15px rgba(50, 50, 50, 0.1);
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme }) => (theme.color.basic.black)};
`;

const ContactName = styled.h2<{dark?: boolean}>`
  ${breakpoints.xs`
    font-size: 22px;
    line-height: 1.23;
    letter-spacing: 0.3px;
    margin-bottom: 10px;
  `}
  ${breakpoints.md`
    font-size: 26px;
  `}
  ${breakpoints.lg`
    font-size: 24px;
    line-height: 0.9;
    margin-bottom: 0;
    bottom: 0;
  `}
  ${breakpoints.xl`
    font-size: 32px;
    letter-spacing: 0.5px;
  `}
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme, dark }) => (dark ? theme.color.basic.black : theme.color.basic.white)};
`;

const ContactPosition = styled.p`
  font-size: 16px;
  font-weight: 200;
  font-family: 'Akkurat';
`;

export {
  HeroHeadline,
  SmallHeadline,
  PageHeadline,
  PitchHeadline,
  SectionHeadline,
  ContactSectionHeadline,
  ContactHeadline,
  ContactName,
  ContactPosition,
};
