import styled from 'styled-components';
import { motion, TargetAndTransition, VariantLabels } from 'framer-motion';
import breakpoints from '../../styles/breakpoints';

type ButtonProps = {
  height?: number,
  width?: number,
  primary?: boolean,
  outlined?: boolean,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  whileHover?: VariantLabels | TargetAndTransition,
  whileTab?: VariantLabels | TargetAndTransition,
  style?: { opacity: string } | { marginBottom: string },
};

const Button = styled(motion.button)<ButtonProps>`
  ${breakpoints.xs`
    height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : 40)}px;
    width: ${({ width }: {width?: Pick<ButtonProps, 'width'>}) => (width ? width : 225)}px;
    padding: 0 25px 0 25px;
    font-size: 14px;
  `}
  ${breakpoints.lg`
    height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : 50)}px;
    width: ${({ width }: {width?: Pick<ButtonProps, 'width'>}) => (width ? width : 260)}px;
    padding: 0 20px 0 20px;
    font-size: 16px;
  `}
  background: ${({ theme, primary }) => (primary ? `linear-gradient(259deg, ${theme.color.butterscotch.tint}, ${theme.color.butterscotch.main}) ` : theme.color.hamam.main)};
  background: ${({ outlined }) => outlined && 'none'};
  border: ${({ theme, primary, outlined }) => (outlined && !primary ? `${theme.color.hamam.main} solid 2px ` : 'none')};
  border-radius: 26px;
  box-shadow: 0 5px 10px 3px rgba(166, 50, 69, 0.1);
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.white};
  color: ${({ theme, outlined }) => outlined && theme.color.hamam.main};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  text-align: center;
  cursor: pointer;

  :focus {
    outline: none;
    border: 0.5px solid ${({ theme, primary }) => (primary ? theme.color.butterscotch.tint : theme.color.hamam.tint)};
  }
`;

const ButtonOutlined = styled(motion.button)<ButtonProps>`
  ${breakpoints.xs`
    min-height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : 40)}px;
    min-width: ${({ width }: {width?: Pick<ButtonProps, 'width'>}) => (width ? width : 225)}px;
    padding: 0 25px 0 25px;
    font-size: 14px;
  `}
  ${breakpoints.lg`
    min-height: ${({ height }: {height?: Pick<ButtonProps, 'height'>}) => (height ? height : 50)}px;
    min-width: ${({ width }: {width?: Pick<ButtonProps, 'width'>}) => (width ? width : 260)}px;
    padding: 0 20px 0 20px;
    font-size: 16px;
  `}
  background: none;
  border: ${({ theme, primary }) => (primary ? `${theme.color.butterscotch.main} solid 2px ` : `${theme.color.hamam.main} solid 2px `)};
  border-radius: 26px;
  box-shadow: 0 5px 10px 3px rgba(166, 50, 69, 0.1);
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme, primary }) => (primary ? `${theme.color.butterscotch.main}` : `${theme.color.hamam.main}`)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  text-align: center;
  cursor: pointer;

  :focus {
    outline: none;
    border: 2.5px solid ${({ theme, primary }) => (primary ? theme.color.butterscotch.main : theme.color.hamam.main)};
  }
`;

export {
  Button,
  ButtonOutlined,
};
