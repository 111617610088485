/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react';
import { useViewportScroll } from 'framer-motion';
import { RemoveScroll } from 'react-remove-scroll';
// recommended by gatsby - we do not need to change this often, no need to ship it from hosting every time
import logo from './typedigital_logo_white.svg';
import {
  Container,
  LogoWrapper,
  StyledBurger,
  Navigation,
  NavLink,
  NavItem,
  NavList,
  Logo,
} from './components';

type ValueProps = {
  light?: boolean,
};

const NavBar: React.FC<ValueProps> = ({ light }) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [hidden, setHidden] = React.useState<boolean>(false);

  const { scrollY } = useViewportScroll();
  React.useEffect(() =>
    scrollY.onChange(() => {
      if (scrollY.get() < scrollY.getPrevious()) {
        setHidden(false);
      } else if (scrollY.get() > 100 && scrollY.get() > scrollY.getPrevious()) {
        setHidden(true);
      }
    }),
  );

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -25 },
  };

  return (
    <>
      {isOpen && (
        <RemoveScroll>
          <Navigation
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.2,
            }}
          >
            <NavList initial={{ x: 300 }} animate={{ x: 0 }} transition={{ when: 'beforeChildren', staggerChildren: 0.2 }} >
              <NavItem>
                <NavLink to='/' activeClassName='active' onClick={() => setOpen(false)}>Start</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to='/leistungen'
                  activeClassName='active'
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Leistungen
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to='/work'
                  activeClassName='active'
                  onClick={() => setOpen(false)}
                >
                  Referenzen
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to='/team' activeClassName='active' onClick={() => setOpen(false)}>Team</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to='/kontakt'
                  activeClassName='active'
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Kontakt
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to='/blog' partiallyActive activeClassName='active' onClick={() => setOpen(false)}>Blog</NavLink>
              </NavItem>
            </NavList>
          </Navigation>
        </RemoveScroll>
      )}

      <Container variants={variants} animate={hidden ? 'hidden' : 'visible'} transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}>
        <LogoWrapper
          isHidden={hidden}
          light={light || isOpen}
          to='/'
          onClick={() => {
            isOpen && setOpen(false);
          }}
        >
          <Logo
            src={logo}
            alt='Logo'
          />
        </LogoWrapper>
        <StyledBurger open={isOpen} isHidden={hidden} onClick={() => setOpen(!isOpen)} aria-label='menu' type='button' light={light || isOpen}>
          <div />
          <div />
          <div />
        </StyledBurger>
      </Container>
    </>
  );
};

NavBar.defaultProps = {
  light: false,
};

export default NavBar;
