import styled from 'styled-components';

const BackgroundButterscotch = styled.div<{onMouseMove?: React.MouseEventHandler<HTMLDivElement>}>`
  min-height: 100vh;
  width: 100%;
  background-image:
    radial-gradient(circle at center, ${({ theme }) => theme.color.butterscotch.main} 30%, rgba(50, 50, 50, 0.1) 100%),
    // svg noise filter in rot
    url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='2' numOctaves='3' stitchTiles='stitch'/%3E%3CfeColorMatrix type='matrix' values='0.5 0 0 0 0 0.05 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0'/%3E%3CfeComponentTransfer%3E%3CfeFuncR type='table' tableValues='0.5 0 0 0.3'/%3E%3CfeFuncG type='table' tableValues='0.05 0 0 0.1'/%3E%3CfeFuncB type='table' tableValues='0 0 0 0'/%3E%3CfeFuncA type='table' tableValues='1 1 1 0'/%3E%3C/feComponentTransfer%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' opacity='0.4'/%3E%3C/svg%3E");
  background-color: ${({ theme }) => theme.color.butterscotch.shade};
`;

const BackgroundSilver = styled.div`
  min-height: 100vh;
  width: 100%;
  background-image:
    radial-gradient(circle at center, ${({ theme }) => theme.color.hamam.alternative} 30%, rgba(50, 50, 50, 0.1) 200%),
    // svg noise filter in grau
    url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='2' numOctaves='3' stitchTiles='stitch'/%3E%3CfeColorMatrix type='saturate' values='0'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' opacity='0.4'/%3E%3C/svg%3E");
  background-color: ${({ theme }) => theme.color.hamam.shade};
`;

const BackgroundDarken = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.08);
`;

export {
  BackgroundButterscotch,
  BackgroundSilver,
  BackgroundDarken,
};
