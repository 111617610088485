/* eslint-disable max-lines */
import styled from 'styled-components';
import { Link } from 'gatsby';

import { SectionHeadline } from '../headlines/index';
import breakpoints from '../../styles/breakpoints';

type NavigationTextProps = {
  key: number,
  to: string,
  onClick?: ((event: React.MouseEvent<HTMLAnchorElement>) => void),
};

type TrustIconProps = {
  key: number,
  src: string,
  alt: string,
  loading: string,
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto;
  place-items: center;
  background: ${({ theme }) => theme.color.basic.black};
  ${breakpoints.xs`
    padding: 0 10vw;
  `}
  ${breakpoints.lg`
    padding: 0 5vw;
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 60px 0;
`;

const TopContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: start;

  ${breakpoints.xs`
    grid-template-rows: 1fr auto;
  `}
  ${breakpoints.md`
    grid-template-columns: 1fr 1fr;
  `}
`;

const LogoWrapper = styled.div`
  grid-column: 1/2;
`;

const Logo = styled('img')`
width: 275px;
height: 75px;
`;

const SubHeadline = styled(SectionHeadline)`
  border-bottom: ${({ theme }) => `3px ${theme.color.butterscotch.main} solid`};
  ${breakpoints.xs`
    font-size: 22px;
    `}
  ${breakpoints.lg`
    font-size: 24px;
    margin-bottom: 20px;
  `}
`;

const CenterContainer = styled.div`
  display: flex;
  margin: 75px 0;
  justify-items: start;
  flex-wrap: wrap;

  ${breakpoints.xs`
    flex-direction: column-reverse;
    flex-wrap: wrap;
    > * + * {
    margin: 0px 50px 50px 0;
    }
  `}
  ${breakpoints.md`
    flex-direction: row;
    > * + * {
    margin: 80px 170px 0 0;
    }
  `}
  ${breakpoints.lg`
      > * + * {
    margin: 0 0 0 250px;
      }
  `}
`;

const ContactContainer = styled.div`
  align-items: start;

  ${breakpoints.xs`
    display: flex;
    flex-direction: column;
    place-items: start;
    > * + * {
    margin-top: 50px;
    }
  `}
  ${breakpoints.md`
    display: grid;
    grid-template-columns: 1fr 1fr;
    > * + * {
      margin: unset;
      margin-left: 95px;
    }
  `}
  ${breakpoints.lg`
    display: flex;
    flex-direction: column;
    > * + * {
    margin: 40px 0 0 0;
    }
  `}

`;

const TextContainer = styled.div<{key: number}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
`;

const LinkContainer = styled.div<{key: number}>`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;

  > * + * {
    margin-bottom: 10px;
  }
`;

const ContactText = styled.p<{key?: number}>`
  ${breakpoints.xs`
    font-size: 16px;
    line-height: 1.43;
    letter-spacing: 0.2px;
    `}
  ${breakpoints.lg`
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: 0.5px;
    `}
  text-decoration: none;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.white};
`;

const LegalText = styled.p`
  ${breakpoints.xs`
    font-size: 13px;
    line-height: 1.43;
    letter-spacing: 0.2px;
    `}
  ${breakpoints.lg`
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: 0.5px;
    `}
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.white};
`;

const NavigationText = styled(Link)<NavigationTextProps>`
  :hover {
    border-bottom: 1px ${({ theme }) => theme.color.butterscotch.main} solid;
  }
  ${breakpoints.xs`
    font-size: 16px;
    line-height: 1.43;
    letter-spacing: 0.2px;
    `}
  ${breakpoints.lg`
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: 0.5px;
    `}
  border-bottom: 1px solid transparent;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.white};
  text-decoration: none;
`;

const LegalLink = styled(Link)<{to: string}>`
  :hover {
    border-bottom: 1px ${({ theme }) => theme.color.butterscotch.main} solid;
  }
  ${breakpoints.xs`
    font-size: 13px;
    line-height: 1.43;
    letter-spacing: 0.2px;
    `}
  ${breakpoints.lg`
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: 0.5px;
    `}
  border-bottom: 1px solid transparent;
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  color: ${({ theme }) => theme.color.basic.white};
  text-decoration: none;
`;

const BottomContainer = styled.div`
  display: grid;
  width: 100%;
  ${breakpoints.xs`
    grid-template-rows: auto;
    margin-top: 5px;
    gap: 35px;
    `}
  ${breakpoints.lg`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
    margin-top: 15px;
    gap: unset;
  `}
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 5px;
  }
`;

const Dot = styled.div`
  content: ' ';
  height: 2px;
  width: 2px;
  border-radius: 50%;
  border: ${({ theme }) => `1px ${theme.color.butterscotch.main} solid`};
  background: ${({ theme }) => theme.color.butterscotch.main};
`;

const SocialContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 25px);
  gap: 25px;

  ${breakpoints.xs`
    place-content: center;
  `}
  ${breakpoints.lg`
    place-content: end;
  `}
`;

const SocialIcon = styled.img<{src: string, alt: string, loading: string}>`
  width: 25px;
  height: 25px;
  filter: invert(100%);
`;

const HorizontalRuler = styled.hr`
  height: 2px;
  width: 100%;
  background: ${({ theme }) => theme.color.basic.white};
`;

const PartnerAndTrustIconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;

  > * + * {
    margin-left: 20px;
  }
  ${breakpoints.xs`
    margin-top: 40px;
    place-self: end start;
  `}
  ${breakpoints.md`
    margin-top: unset;
    place-self: end;
  `}
`;

const CompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TrustIcon = styled.img<TrustIconProps>`
  ${breakpoints.xs`
    width: 80px;
    height: 60px;
  `}
  ${breakpoints.lg`
    width: 130px;
    height: 130px;
  `}
`;

export {
  Container,
  ContentContainer,
  TopContainer,
  CenterContainer,
  BottomContainer,
  ContactContainer,
  TextContainer,
  SocialContainer,
  RightContainer,
  LinkContainer,
  SocialIcon,
  SubHeadline,
  ContactText,
  NavigationText,
  LogoWrapper,
  Logo,
  HorizontalRuler,
  Dot,
  CompanyContainer,
  PartnerAndTrustIconsContainer,
  TrustIcon,
  LegalText,
  LegalLink,
};
