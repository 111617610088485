import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type NameMetaObj = {
  name: string,
  content: string | undefined,
};

type PropertyMetaObj = {
  property: string,
  content: string | undefined,
};

type ValueProps = {
  description?: string,
  lang?: string,
  meta?: ConcatArray<NameMetaObj | PropertyMetaObj>,
  title: string,
  image?: string,
  contentType?: string,
  noIndex?: boolean,
};

type queryData = {
  site: {
    siteMetadata: {
      title: string,
      description: string,
      author: string,
    },
  },
};

const SEO: React.FC<ValueProps> = (
  { description, lang, meta, title, image, contentType, noIndex },
) => {
  const { site }: queryData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description ?? site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `format-detection`,
          content: `telephone=no`,
        },
        {
          name: `title`,
          content: title,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:type`,
          content: contentType,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        noIndex ?
          {
            name: `robots`,
            content: `noindex, nofollow`,
          }
          : {},
      ].concat(meta ?? [])}
    />
  );
};

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
  noIndex: false,
  image: ``,
  contentType: `website`,
};

export default SEO;
