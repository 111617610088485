import React, { Component, ErrorInfo, ReactNode } from 'react';

type Props = {
  children: ReactNode,
};

type State = {
  error: Error | null,
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error });
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  render(): React.ReactNode {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      // render fallback UI
      return <h1>Something went wrong!</h1>;
    }
    // when there's not an error, render children untouched
    return children;
  }
}

export default ErrorBoundary;
