import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import defaultTheme from '../styles/theme';
import GlobalStyles from '../styles/global-styles';
import GlobalFonts from '../fonts/global-fonts';
import MoskBold from '../fonts/mosk-extra-bold-800.ttf';
import MoskBoldWoff from '../fonts/mosk-extra-bold-800.woff';
import MoskBoldWoff2 from '../fonts/mosk-extra-bold-800.woff2';
import MoskMedium from '../fonts/mosk-medium-500.ttf';
import MoskMediumWoff from '../fonts/mosk-medium-500.woff';
import MoskMediumWoff2 from '../fonts/mosk-medium-500.woff2';
import Akkurat from '../fonts/akkurat.ttf';
import AkkuratWoff from '../fonts/akkurat.woff';
import AkkuratWoff2 from '../fonts/akkurat.woff2';
import ErrorBoundary from './error-boundary';

type LayoutProps = {
  children: React.ReactNode,
};

const Layout: React.FC<LayoutProps> = ({ children }) =>
  <>
    <ErrorBoundary>
      <ThemeProvider theme={defaultTheme}>
        <Helmet>
          <link
            rel='preload'
            as='font'
            href={MoskBold}
            type='font/ttf'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            as='font'
            href={MoskBoldWoff}
            type='font/woff'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            as='font'
            href={MoskBoldWoff2}
            type='font/woff2'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            as='font'
            href={MoskMedium}
            type='font/ttf'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            as='font'
            href={MoskMediumWoff}
            type='font/woff'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            as='font'
            href={MoskMediumWoff2}
            type='font/woff2'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            as='font'
            href={AkkuratWoff}
            type='font/woff'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            as='font'
            href={AkkuratWoff2}
            type='font/woff2'
            crossOrigin='anonymous'
          />
          <link
            rel='preload'
            as='font'
            href={Akkurat}
            type='font/ttf'
            crossOrigin='anonymous'
          />
        </Helmet>
        <GlobalFonts />
        <GlobalStyles />
        <main>{children}</main>
      </ThemeProvider>
    </ErrorBoundary>
  </>;

export default Layout;
