import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import usePlausible from '../../hooks/use-plausible';
// recommended by gatsby - we do not need to change this often, no need to ship it from hosting every time
import logoWithSlogan from './typedigital_logo_slogan.svg';

import {
  Container,
  ContentContainer,
  TopContainer,
  CenterContainer,
  BottomContainer,
  TextContainer,
  LinkContainer,
  LogoWrapper,
  Logo,
  SubHeadline,
  ContactText,
  NavigationText,
  HorizontalRuler,
  ContactContainer,
  SocialContainer,
  SocialIcon,
  RightContainer,
  Dot,
  CompanyContainer,
  PartnerAndTrustIconsContainer,
  TrustIcon,
  LegalText,
  LegalLink,
} from './components';

type QueryData = {
  footerInformation: {
    nodes: {
      elevatorPitch: string,
      contactColumn: {
        headline: string,
        sublines: {
          name: string,
          city: string,
          street: string,
          mail: string,
          phone: string,
        },
      }[],
      navigationColumn: {
        headline: string,
        sublines: {
          name: string,
          path: string,
          plausibleId: string,
        }[],
      }[],
      trustIconsAndPartner: {
        icon: {
          file: {
            url: string,
          },
        },
        name: string,
      }[],
      socialMedia: {
        title: string,
        link: string,
        image: {
          file: {
            url: string,
          },
        },
      }[],
    }[],
  },
};

type FooterProps = {
  light?: boolean,
};

const Footer: React.FC<FooterProps> = () => {
  const { plausible } = usePlausible();
  const { footerInformation }: QueryData = useStaticQuery(graphql`
    query {
      footerInformation: allContentfulFooter {
        nodes {
          elevatorPitch
          contactColumn {
            headline
            sublines {
              ... on ContentfulAddress {
                name
                city
                street
              }
              ... on ContentfulContactInformation {
                mail
                phone
              }
            }
          }
          navigationColumn {
            headline
            sublines {
              ... on ContentfulFooterLinks {
                name
                path
                plausibleId
              }
            }
          }
          trustIconsAndPartner  {
            icon {
              file {
                url
              }
            }
            name
          }
          socialMedia {
            title
            link
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  const {
    nodes:
    [{ contactColumn, navigationColumn, trustIconsAndPartner, socialMedia }],
  } = footerInformation;

  const mappedContactElements = contactColumn.map(
    (element, index) => {
      switch (index) {
        case 0:
          return (
            <TextContainer key={index}>
              <SubHeadline>{element.headline}</SubHeadline>
              {Object.keys(element.sublines[0]).map(
                (key, token) =>
                  key !== '__typename' && (
                    <ContactText key={token}>
                      {element.sublines[0][key]}
                    </ContactText>
                  ),
              )}
            </TextContainer>
          );
        case 1:
          return (
            <TextContainer key={index}>
              <SubHeadline>{element.headline}</SubHeadline>
              {Object.keys(element.sublines[0]).map(
                (key, token) =>
                  key !== '__typename' && (
                    <ContactText key={token}>
                      {element.sublines[0][key]}
                    </ContactText>
                  ),
              )}
            </TextContainer>
          );
        default:
          return <h1>it appears that contactColumn is not defined</h1>;
          // console.warn('it appears that contactColumn is not defined');
      }
    },
  );

  const mappedLinkElements = navigationColumn.map(
    (element, index) => (
      <LinkContainer key={index}>
        <SubHeadline>{element.headline}</SubHeadline>
        {element.sublines.map((link, idx) => (
          <NavigationText
            key={idx}
            to={link.path}
            onClick={() => {
              plausible(`${link.plausibleId}`);
            }}
          >
            {link.name}
          </NavigationText>
        ))}
      </LinkContainer>
    ),
  );

  return (
    <>
      <Container>
        <ContentContainer>
          <TopContainer>
            <CompanyContainer>
              <LogoWrapper>
                <Logo src={logoWithSlogan} />
              </LogoWrapper>
            </CompanyContainer>
            <PartnerAndTrustIconsContainer>
              {trustIconsAndPartner.map(
                (icon, index) => (
                  <TrustIcon
                    key={index}
                    src={icon.icon.file.url}
                    alt={icon.name}
                    loading='lazy'
                  />
                ),
              )}
            </PartnerAndTrustIconsContainer>
          </TopContainer>
          <CenterContainer>
            <ContactContainer>{mappedContactElements}</ContactContainer>
            {mappedLinkElements}
          </CenterContainer>
          <HorizontalRuler />
          <BottomContainer>
            <RightContainer>
              <LegalText>
                &copy;
                {' '}
                {new Date().getFullYear()}
                {' '}
                typedigital
              </LegalText>
              <Dot />
              <LegalLink to='/datenschutz'>Datenschutz</LegalLink>
              <Dot />
              <LegalLink to='/impressum'>Impressum</LegalLink>
            </RightContainer>
            <SocialContainer>
              <>
                {socialMedia.map((link, index) => (
                  <a
                    key={index}
                    href={link.link}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <SocialIcon
                      src={link.image.file.url}
                      alt={link.title}
                      loading='lazy'
                    />
                  </a>
                ))}
              </>
            </SocialContainer>
          </BottomContainer>
        </ContentContainer>
      </Container>
    </>
  );
};

export default Footer;
