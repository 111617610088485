type ReturnValue = {
  plausible: (trackingId: string) => void,
};

export default function usePlausible(): ReturnValue {
  if (typeof window === 'undefined') {
    return {
      // eslint-disable-next-line no-console
      plausible: (trackingId) => console.log(`Called plausible with: plausible('${trackingId}')`),
    };
  }
  if (typeof window.plausible === 'undefined') {
    return {
      // eslint-disable-next-line no-console
      plausible: (trackingId) => console.log(`Called plausible with: plausible('${trackingId}')`),
    };
  }
  return {
    plausible: window.plausible,
  };
}

